<template>
	<SubPageHeader :headerTitle="getHeaderTitle"></SubPageHeader>
	<div class="content">
		<div class="container-fluid">
			<component :is="$store.state.component">

			</component>
		</div>
	</div>
</template>
<script>
import SubPageHeader from '../SubPageHeader.vue'
import AllImagingList from '../../components/patient/echart/imaging.vue';

export default {
	data() {
			return {
		
		}
	},
	components: {
		SubPageHeader,
		AllImagingList,

	},
	created() { 
		this.$store.state.component = this.$route.name;
	},
	mounted() {

	},
	methods: {
	},
	computed: {
		getHeaderTitle() {
			const currentRoute = this.$route;
			if (currentRoute.meta && currentRoute.meta.headerTitle) {
				return currentRoute.meta.headerTitle;
			} else {
				const parentRoute = currentRoute.matched[0];
				if (parentRoute.meta && parentRoute.meta.headerTitle) {
					return parentRoute.meta.headerTitle;
				} else {
					return 'Default Header Title';
				}
			}
		},
  },
}
</script>